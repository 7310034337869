
.popup {
    background-color: rgb(255, 255, 255, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
}

.popup_dialog {
    background: white;
    padding: 30px;
    width: 540px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 0.431);
}

.image {
    margin: -30px;
    width: 540px;
}

.popup-open {
    display: grid;
}

.popup-title {
    padding: 20px;
}

.popup-input .btn-sm {
    margin-inline: 20px;
}

.popup-input {
    margin: 20px;
}

.modal-content {
    border-radius: 30px;
}

.btn-secondary {
    display: inline-flex !important;
}

.btn-close {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.btn-sm.btn-cancel {
    background-color: #c0c0c0; 
    color: #000; 
    border: none;
    border-radius: 10px; 
    padding: 10px 20px; 
    font-size: 1em;
    cursor: pointer; 
    margin-right: 8px; 
    outline: none; 
  }
  
  .btn-sm.btn-confirm {
    background-color: #ff4d4d; 
    color: #fff; 
    border: none;
    border-radius: 10px; 
    padding: 10px 20px; 
    font-size: 1em;
    cursor: pointer; 
    outline: none; 
  }
  
  .btn-sm:hover {
    opacity: 0.8;
  }